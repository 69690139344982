import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import jQuery from 'jquery';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import countryJs  from '../lib/Country';
import 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
import "../pages/CourseDetails.css";
import "chart.js/auto";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import parse from 'html-react-parser';
import striptags from "striptags";
import Swal from 'sweetalert2';
import PageNotFound from "./PageNotFound";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
AOS.init();

function CourseDetails() { 
    const params = useParams();
    const [thisCourse, setThisCourse] = useState([]);
    const [allcourses, setThisAllCourse] = useState([]);
    const [courseCheck, setThisCourseCheck] = useState([]);
    const [specs, setThisSpecs] = useState([])
    const [spec, setThisSpec] = useState([])
    const [salaries, setThisSalary] = useState([])
    const [faqs, setThisFaq] = useState([])
    const [faq, setFaq] = useState([])
    const [enrolls, setThisEnroll] = useState([])
    const [enroll, setEnroll] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate();
      function handleSubmit(event) {
         event.preventDefault();
         const formData = new FormData(event.target);
         jQuery(".page-loader").fadeIn(300);
         axios.post('/store-enquiry', formData)
         .then(({data})=>{
            jQuery(".page-loader").fadeOut(300);
            event.target.reset();
            if(data.status === 200){
              jQuery(window).scrollTop(0);
              navigate('/thanks');
            }
            else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields',
                    icon:"error"
                })
            }
            else{
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
            }
         })
       }

    const chartOptions = {
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            indexAxis: 'y',
            responsive: true,
            plugins: {
                legend: {
                    display: false
                },
                datalabels: {
                    formatter: function(value, context) {
                        return '₹' + value.toLocaleString();
                    },
                    display: 'auto',
                    color: '#fff',
                    backgroundColor: '#66aa05',
                    borderColor: '#66aa05',
                    borderWidth: 1,
                    borderRadius: 30,
                    padding: 4,
                    anchor: 'center',
                    clamp: true,
                    align: 'end',
                    clip: true,
                    offset: 0,
                    font: {
                        weight: 'normal'
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: false,
                    grid: {
                        display: false,
                    }
                },
                x: {
                    display: false,
                    beginAtZero: false,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        callback: function(value) {
                            return '₹' + this.getLabelForValue(value);
                        }
                    }
                }
            }
    };
    const [countries, setCountry] = useState('');
    const [states, setStates] = useState([]);
    const [stateValue, setStateValue] = useState([]);
    const url = window.location.pathname.split('/').pop();
    function selectCountry (event) {
        setStateValue([])
        const state_arr = countryJs.s_a[event.key].split("|"); 
        const stateArray = [];
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        setStates(stateArray)
    }
        
    function selectRegion (val) {
        if(val.value !== '' && val.value !== null){
          setStateValue(val)
        } else {
          setStateValue([])
        }
    }
    useEffect(()=>{
        fetchProduct(params.id);
        const countryArray = [];
           var country1 = countryJs.country_arr;
           for (var i = 0; i < country1.length; i++) {
             countryArray.push({
               value: country1[i],
               label: country1[i],
               key:[i+1]
             });
           }
        setCountry(countryArray);
    },[url])

    const fetchProduct = async () => {
        setIsLoading(true)
        await axios.get(`/course-page-data/${params.id}`).then(({data})=>{
          setThisCourse(data.course)
          setThisCourseCheck(data.courseCheck)
          setThisAllCourse(data.allcourses)
          setThisSpecs(data.specs)
          setThisSpec(data.spec)
          setThisSalary(data.salaries)
          setThisFaq(data.faqs)
          setFaq(data.faq)
          setThisEnroll(data.enrolls)
          setEnroll(data.enroll)
          setIsLoading(false)
        }).catch(({response:{data}})=>{
            setIsLoading(false)
            return navigate("404");
        })
      }


      var imgUrl = 'https://www.senfineacademy.com/storage/courses/'+thisCourse.image;
      function ThisCourseDate(par){
        const months = ["01","02","03","04","05","06","07","08","09","10","11","12"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        const hour = today.getHours();
        const minute = today.getMinutes();
        const second = today.getSeconds();
        return year+'-'+month+'-'+date+'T'+hour+':'+minute+':'+second+'+05:30';
    }
    function updatedDate(par){
            const today = new Date(par);
            const date = today.getDate();
            return date;
    }
    function updatedMonth(par){
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const today = new Date(par);
            const month = months[today.getMonth()];
            return month;
    }
    function updatedYear(par){
            const today = new Date(par);
            const year = today.getFullYear();
            return year;
    }
        
  return (
    thisCourse!==null?
    <div className="course-details">
      {/*<Helmet>
        <title>{thisCourse.meta_title}</title>
        <meta name="description" content={thisCourse.meta_des}/>
        <meta name="keywords" content={thisCourse.meta_key}/>
        <link rel="canonical" href={`https://www.senfineacademy.com/courses/${thisCourse.slug}`}/>
        <meta property="article:publisher" content="https://www.facebook.com/senfineacademy" />
        <meta property="article:author" content="https://www.facebook.com/senfineacademy" />
        <meta property="article:section" content={thisCourse.course} />
        <meta property="article:published_time" content={ThisCourseDate(new Date(thisCourse.created_at))} />
        <meta property="article:modified_time" content={ThisCourseDate(new Date(thisCourse.updated_at))} />
        <meta property="og:title" content={thisCourse.meta_title} />
        <meta property="og:url" content={`https://www.senfineacademy.com/courses/${thisCourse.slug}`} />
        <meta property="og:description" content={thisCourse.meta_des} />
        <meta property="og:updated_time" content={ThisCourseDate(new Date(thisCourse.updated_at))} />
        <meta property="og:image" content={`https://www.senfineacademy.com/storage/courses/${thisCourse.image}`} />
        <meta property="og:image:secure_url" content={`https://www.senfineacademy.com/storage/courses/${thisCourse.image}`} />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="402" />
        <meta property="og:image:alt" content={thisCourse.course}  />
      </Helmet>*/}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'linear-gradient(130deg, rgb(0 33 71 / 80%),rgb(0 33 71 / 20%)), url(' + imgUrl + ')'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    <h1 style={{color:"#8fff9b", textTransform: "uppercase", fontWeight:"500"}}>{(() => {if(thisCourse.htag!=null){
                        return(thisCourse.htag)
                        }else if(thisCourse.htag==null)
                        {return(thisCourse.course)}
                        })()}</h1>
                    <p>{thisCourse.course_desc}</p>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/courses">Courses</Link></li>
                        <li className="active">{thisCourse.course}</li>
                    </ul>
                    <div className="course-details-area">
                    <div className="course-details-info">
                      <p className="updated-p">Last updated: {updatedDate(new Date(thisCourse.updated_at))} {updatedMonth(new Date(thisCourse.updated_at))}, {updatedYear(new Date(thisCourse.updated_at))}</p>
                      <div className="course-meta" itemScope itemType="https://schema.org/Product">
                        <span className="hidden" itemProp="name">{thisCourse.course}</span>
                        <div className="item rating" itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
                            <h4>Rating</h4>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star-half-alt"></i>
                            <span><span itemProp="ratingValue"> {thisCourse.rating}</span> (<span itemProp="ratingCount">{thisCourse.rating_count}</span>)</span>
                            <meta itemProp="bestRating" content = "5" />
                            <meta itemProp="worstRating" content = "0" />
                        </div>
                      </div>
                      <Link className="btn btn-theme b1" style={{marginBottom:"10px"}} to="#senfineApply" data-toggle="modal" data-target="#senfineApply"><i className="fa fa-check" aria-hidden="true"></i> Apply Now</Link>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="about-area senfine-features-area">
        <div className="container">
            <div className="our-features">
                <div className="features-grid">
                
                    <div className="grid-item">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-online-course"></i>
                            </div>
                            <div className="info">
                                <p>Learning Format</p>
                                <h4>Classroom / Online</h4>
                            </div>
                        </div>
                    </div>
                    
                    {(() => {if(thisCourse.course_duration!=null)
                    {return(
                    <div className="grid-item">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-training"></i>
                            </div>
                            <div className="info">
                                <p>Program Duration</p>
                                <h4>{thisCourse.course_duration}</h4>
                            </div>
                        </div>
                    </div>
                    )}})()}
                   
                    <div className="grid-item">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-scholarship"></i>
                            </div>
                            <div className="info">
                                <p>Flexible</p>
                                <h4>EMI Options</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <div className="course-details-area default-padding">
        <div className="container">
        {isLoading ? (
             <div className="row">
             <div className="col-md-12 text-center">
                 <RotatingLines
                    visible={true}
                    height="40"
                    width="40"
                    strokeColor="#0476bc"
                    strokeWidth="4"
                    animationDuration="0.75"
                    ariaLabel="Loading"
                 />
                 <p>Please wait...</p>
             </div>
             </div>
            ) : (
            <div className="row">
                <div className="col-md-8">
                    <div className="course-details-info">
                      <div className="info title">
                      <div data-aos="fade-right" data-aos-offset="0" data-aos-duration="800" data-aos-delay="0" dangerouslySetInnerHTML={{ __html: thisCourse.overview_new }} />
                      </div>
                      {(() => {
                        if (enroll!=null) {
                        return (
                      <div className="info title">
                          <h3> {(() => {if(thisCourse.why_enroll_title!=null){
                        return(thisCourse.why_enroll_title)
                        }else if(thisCourse.why_enroll_title==null)
                        {return 'Why join '+(thisCourse.course)+'?'}
                        })()}</h3>
                      </div>
                      )
                    }
                    })()}
                    {(() => { if(enroll!=null) {
                        return (
                      <div className="why-enroll-boxes text-center">
                      {enrolls && enrolls.length > 0 && enrolls.map((enroll, index) => {
                            return (
                        <div className="why-enroll-single" data-aos="zoom-in-up" data-aos-offset="0" data-aos-duration="1000" data-aos-delay="0" key={index}>
                            <div className="circle"><i className={enroll.icon}></i></div>
                            <div className="info">
                                <h5><b>{enroll.heading}</b></h5>
                                <p><span dangerouslySetInnerHTML={{ __html: enroll.content }} /></p>
                            </div>
                        </div>
                            );
                        })}
                       </div>
                       )
                    }
                    })()}
                            {(() => {
                            if(thisCourse.course_highlights!=null) {
                            return (
                            <div className="sidebar" style={{margin: "0 0 20px 0"}}>
                            <div className="sidebar-item category highlights hidden visible-xs" data-aos="zoom-out-up" data-aos-offset="0" data-aos-duration="1000" data-aos-delay="50">
                                <div className="title">
                                    <h4>Course Highlights</h4>
                                </div>
                                <div className="sidebar-info">
                                    <ul>
                                        <div dangerouslySetInnerHTML={{ __html: thisCourse.course_highlights }} />
                                    </ul>
                                </div>
                            </div>
                            </div>
                            )
                            }
                            })()}

                      <div className="info title">
                      <div data-aos="fade-right" data-aos-offset="0" data-aos-duration="800" data-aos-delay="0" dangerouslySetInnerHTML={{ __html: thisCourse.benefits }} />
                      <div data-aos="fade-right" data-aos-offset="0" data-aos-duration="800" data-aos-delay="0" dangerouslySetInnerHTML={{ __html: thisCourse.career_scope }} />
                      </div>
                      {(() => {
                      if(spec!=null) {
                      return (
                      <div className="info title">
                            <h3>Salary Trends of {thisCourse.course}</h3>
                      </div>
                      )
                      }
                      })()} 
                      <div className="salary-tabs tab-info">
                        <ul className="nav nav-pills">
                        {specs && specs.length > 0 && specs.map((spec, index) => {
                           const tabindex = '#tab' + index
                            return (
                          <li className={(index == 0 ? 'active' : '')} key={tabindex}>
                            <a data-toggle="tab" href={tabindex} aria-expanded="true">
                            {spec.specialization}
                            </a>
                            </li> 
                            );
                        })}
                         </ul>
                        <div className="tab-content tab-content-info">
                        {salaries && salaries.length > 0 && salaries.map((salary, index) => {
                            const tabindex = 'tab'+ `${index}`;
							return (
                                <div id={tabindex} className={"tab-pane fade " + (index == 0 ? 'active in' : 'in')} key={tabindex}>
                                <div className="info title text-center">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Salary</h5>
                                            <div className="chart-container">
                                            <Bar
                                            data={{
                                                labels: ['Min Salary', 'Average Salary', 'Max Salary'],
                                                datasets: [
                                                {
                                                    label: 'Salary',
                                                    data: [salary.min, salary.average, salary.max],
                                                    backgroundColor: '#66aa05',
                                                    borderWidth: 1
                                                }
                                                ],
                                            }}
                                            options={chartOptions}
                                            />
                                            </div>
                                        </div>
                                        {(() => {if(salary.logo!=null){
                                        return(
                                        <div className="col-md-6">
                                            <h5>Placements</h5>
                                            <div className="company-logos">
                                            {JSON.parse(salary.logo).map((img) => (
                                                <img key={img.image} src={`/storage/${img.image}`} loading="lazy" alt={img.image} />
                                            ))}
                                            </div>
                                        </div>)}
                                        }
                                    )()}
                                    </div>
                                </div>
                            </div>
                                );
                            })}

                        </div>
                    </div>
                       
                    <div className="info title">
                        <h3>Associated Partners</h3>
                        <div className="partners-section">
                            <img src="/assets/img/logos/google.png" alt="Google" loading="lazy" />
                            <img src="/assets/img/logos/ibm.png" alt="IBM" loading="lazy" />
                            <img src="/assets/img/logos/tcs.png" alt="TCS" loading="lazy" />
                            <img src="/assets/img/logos/amazon.png" alt="Amazon" loading="lazy" />
                            <img src="/assets/img/logos/aws.png" alt="AWS" loading="lazy" />
                            <img src="/assets/img/logos/microsoft.png" alt="Microsoft" loading="lazy" />
                        </div>
                    </div>   
                <div className="info title">
                         <h3>Testimonials</h3>
                         <div className="clients-review-carousel owl-carousel owl-theme" id="senfine-testimonial-inner1" style={{ marginBottom: 20 }}>
                         <Swiper id="clients-review-carousel"
                        spaceBetween={10}
                        pagination={{clickable: "true"}}
                        breakpoints={{ 320: { slidesPerView: 1,}, 1024: { slidesPerView: 2,}}}
                        loop={true}
                        // autoplay={{enable: "true", delay: "2000", pauseOnMouseEnter: "true",}}
                    >
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="/assets/img/testimonials/senfine-testimonial-nikhilps.jpg" loading="lazy" alt="Nikhil" />
                                    </div>
                                    <div className="others">
                                        <h4>Nikhil</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    My immense experience started on the first day, when I realised I was not just in a academy but in the corporate world. In addition to the wonderful teaching, a variety of activities were conducted to help us discover our hidden talents, build our strengths, and learn how to work as a team to overcome our weaknesses. There is a good learning environment that is enjoyable and friendly, with knowledgeable teaching staff.
                                </p>
                                </div>
                                
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="/assets/img/testimonials/senfine-testimonial-chandanakoti.jpg" loading="lazy" alt="Chandana" />
                                    </div>
                                    <div className="others">
                                        <h4>Chandana</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    I am happy that I chose Senfine Academy to complete my AI & MI coursework because all of the faculty members provide excellent care for their students both academically and extra curricularly. The college's placement assistants are to be commended for advancing my careers because they give all students effective pre-placement training that prepares us for the workplace and gives us all the chance to realise our dreams.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="/assets/img/testimonials/y.png" loading="lazy" alt="Yashuraj" />
                                    </div>
                                    <div className="others">
                                        <h4>Yashuraj</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    As far as the Academic is concerned, the Senfine syllabus provides an excellent foundation for a smooth take off in my careers. After I joined Senfine, I gained extensive knowledge about management and business, as well as a path to follow as I built my career and became an authority in finance and human resources. The distinctive characteristics of Senfine Academy set it apart from other institutes.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="/assets/img/testimonials/k.png" loading="lazy" alt="Komal" />
                                    </div>
                                    <div className="others">
                                        <h4>Komal</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    Senfine Academy has been a great contributor to the development of my personality. I have established my leadership, time management and team skills and have also been able to advance these skills to the whole new augment level. The infrastructure of Senfine is one of the finest in the Bangalore is what stands out the most. The best thing about this academy is the cross-culture interaction between students from various parts of the country.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        </Swiper>
                        </div>
                    </div>
                {/* <div className="related-courses">
                    <div className="info title">
                        <h3>Explore More Courses</h3>
                    </div>
                    <div className="related-courses-content related-courses-carousel" id="related-courses">
                    <Swiper 
                      spaceBetween={10}
                      pagination={{clickable: "true"}}
                      breakpoints={{ 320: { slidesPerView: 2,}, 1024: { slidesPerView: 4,}}}
                      loop={true}
                      autoplay={{enable: "true", pauseOnMouseEnter: "true",}}
                    >
                        {allcourses.map((course, index) => (
                        <SwiperSlide key={`c${course.id}`}>
                        <div className="single-item" >
                            <div className="item">
                                <Link to={`/courses/${course.slug}`}>
                                <div className="thumb">
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" alt={course.course} />
                                </div>
                                <div className="info">
                                    <h5>
                                        {course.course}
                                    </h5>
                                    <p className="meta">{course.institution}</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                        </SwiperSlide> 
                        ))}
                    </Swiper>
                    </div>
                </div> */}
                {(() => {
                        if (faq!=null) {
                        return (
                        <div className="info title">
                        <h3>FAQs for {thisCourse.course}</h3>
                        <div className="course-faqs">
                            <div className="course-list-items acd-items acd-arrow">
                                <div className="panel-group symb" id="courseFaqs" role="tablist" aria-multiselectable="true">
                                        {faqs && faqs.length > 0 && faqs.map((faq, index) => {
                                            const faqindex = "#cfaq"+ `${index}`;
                                            const faqdiv = "cfaq"+ `${index}`;
                                            return (
                                                <div className="panel panel-default" key={faqindex}>
                                                    <div className="panel-heading">
                                                        <h5 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#courseFaqs" href={faqindex} aria-expanded={ (index == 0 ? 'true' : 'false')} className={(index != 0 ? 'collapsed' : '')}>
                                                                {parse(striptags(faq.question))}
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id={faqdiv} className={"panel-collapse collapse " + (index == 0 ? 'in' : '')} aria-expanded={ (index == 0 ? 'true' : 'false')}>
                                                        <div className="panel-body">
                                                                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                        }
                        })()}
                </div>
                </div>
                <div className="col-md-4">
                    <div className="sidebar">
                        <aside>
                        <div className="sidebar-item side-form" data-aos="zoom-out-up" data-aos-offset="0" data-aos-duration="1000" data-aos-delay="0">
                                <h4>Apply Now</h4>
                                <p>Submit your details to get callback.</p>
                                <div className="reg-form">
                                <form onSubmit={handleSubmit} autoComplete="off" className="form contact-form">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input className="form-control" name="student_name" placeholder="Name *" type="text" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input className="form-control" name="mobile" placeholder="Mobile Number *" type="text" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input className="form-control" name="email" placeholder="Email *" type="email" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input className="form-control" name="city" placeholder="City *" type="text" required />
                                            <input type="hidden" name="course" value={thisCourse.course}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn btn-success effect" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                            </div>
                            {(() => {
                            if(thisCourse.course_highlights!=null) {
                            return (
                            <div className="sidebar-item category highlights hidden-xs" data-aos="zoom-out-up" data-aos-offset="0" data-aos-duration="1000" data-aos-delay="50">
                                <div className="title">
                                    <h4>Course Highlights</h4>
                                </div>
                                <div className="sidebar-info">
                                    <ul>
                                        <div dangerouslySetInnerHTML={{ __html: thisCourse.course_highlights }} ></div>
                                    </ul>
                                </div>
                            </div>
                            )
                            }
                            })()}
                            {(() => {
                            if (thisCourse.skills!=null) {
                            return (
                            <div className="sidebar-item category" data-aos="zoom-out-up" data-aos-offset="0" data-aos-duration="1000" data-aos-delay="50">
                                <div className="title">
                                    <h4>Skills You'll Gain</h4>
                                </div>
                                <div className="sidebar-info">
                                    <ul>
                                        <div dangerouslySetInnerHTML={{ __html: thisCourse.skills }}></div>
                                    </ul>
                                </div>
                            </div>
                            )
                            }
                            })()}
                            
                            <div className="sidebar-item side-contact" data-aos="zoom-out-up" data-aos-offset="0" data-aos-duration="1000" data-aos-delay="50">
                                <p>Contact us for Admission</p>
                                <h4><a href="tel:+919845412266">+91 9845412266</a></h4>
                                <img src="/assets/img/course-contact1.webp" alt="Contact us" loading="lazy" className="course-contact-img" draggable="false"></img>
                            </div>
                            {(() => {
                            if (courseCheck!=null) {
                            return (
                            <div className="sidebar-item recent-post">
                                <div className="title">
                                    <h4>Explore More Courses</h4>
                                </div>
                                {allcourses.map((course, index) => (
                                <div className="item" key={`c${course.id}`}>
                                    <div className="content">
                                        <div className="thumb">
                                            <Link to={`/courses/${course.slug}`} style={{ padding: "14px 0" }}>
                                                <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                            </Link>
                                        </div>
                                        <div className="info">
                                            <h5>
                                                <Link to={`/courses/${course.slug}`}>{course.course}</Link>
                                            </h5>
                                            <div className="course-meta">
                                                <p class="small mb-0">{course.institution}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                            )
                            }
                            })()}
                        </aside>
                    </div>
                </div>
            </div>
            )}
        </div>
    </div>
    <div className="about-area demo-cert-area">
        <div className="container">
            <div className="demo-cert">
                <div className="left-side">
                    <div className="site-heading text-left">
                        <h2>Why Learn {thisCourse.course} in Bangalore at Senfine Academy?</h2>
                    </div>
                    <ul>
                        <li><i className="fas fa-desktop"></i> Live Capstone Projects</li>
                        <li><i className="fas fa-graduation-cap"></i> Real time Industry Experts as Trainers</li>
                        <li><i className="fas fa-trophy"></i> Placement Support till you get your Dream Job offer!</li>
                        <li><i className="fas fa-chalkboard-teacher"></i> Free Interview Clearing Workshops</li>
                        <li><i className="far fa-file-alt"></i> Free Resume Preparation & Aptitude Workshops</li>
                    </ul>
                    <div className="col-md-offset-4 col-xs-offset-3 col-md-4 col-xs-6 text-center">
                        <Link className="btn btn-primary" style={{marginBottom:"10px", width:"100%"}} to="#senfineApply" data-toggle="modal" data-target="#senfineApply"><i className="fa fa-check" aria-hidden="true"></i> Apply Now</Link>
                    </div>
                </div>
                <div className="right-side">
                    <div className="cert-wrap" data-aos="flip-up" data-aos-offset="0" data-aos-duration="1000" data-aos-delay="50">
                        <span className="top-view"></span>
                        <img src="/assets/img/demo_cert1.webp" alt={thisCourse.course} loading="lazy" draggable="false"/>
                        <span className="bottom-view"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    :
	<h1> Can't connect to server ! </h1>
);
}

export default CourseDetails;
