import React from "react";
import { useState, useEffect } from 'react';
import jQuery from 'jquery';
import Select from 'react-select';
import countryJs  from '../../lib/Country';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RotatingLines } from 'react-loader-spinner';

function App() {
    
    const [value, onChange] = useState(new Date());
    const [courseOptions, setCourse] = useState([]);
    const fetchProduct = async () => {
        await axios.get(`/all-app-courses`).then(({data})=>{
            setCourse(data.appcourses)
        })
      }
      
      let navigate = useNavigate();
      function handleSubmit(event) {
         event.preventDefault();
         const formData = new FormData(event.target);
         jQuery(".page-loader").fadeIn(300);
         jQuery('.senfine-modal').fadeOut(300);
         jQuery('.senfine-modal').removeClass('in');
         jQuery('body').removeClass('modal-open');
         jQuery('body').css('padding-right', '');
         jQuery('.modal-backdrop.in').remove();
         axios.post('/store-enquiry', formData)
         .then(({data})=>{
            jQuery(".page-loader").fadeOut(300);
            event.target.reset();
            if(data.status === 200){
              jQuery(window).scrollTop(0);
              navigate('/thanks');
              localStorage.setItem('applyFormSubmitted', 'true');
            }
            else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields',
                    icon:"error"
                })
            }
            else{
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
            }
         })
       }

    const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
    const url = window.location.pathname.split('/').pop();
    function selectCountry (event) {
        setStateValue([])
        const state_arr = countryJs.s_a[event.key].split("|"); 
        const stateArray = [];
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        setStates(stateArray)
    }
    function selectRegion (val) {
        if(val.value !== '' && val.value !== null){
          setStateValue(val)
        } else {
          setStateValue([])
        }
    }

    useEffect(()=>{
        fetchProduct();
        const countryArray = [];
           var country1 = countryJs.country_arr;
           for (var i = 0; i < country1.length; i++) {
             countryArray.push({
               value: country1[i],
               label: country1[i],
               key:[i+1]
             });
           }
        setCountry(countryArray);

        jQuery(document).on('click', '.scourse', function(){
            var name = jQuery(this).attr('c-name');
            jQuery('#crs').val(name);
            jQuery('#coursename').text(name);
            jQuery('#crs').trigger('change');
            
        });
        jQuery(document).on('scroll', function() {
            var y = jQuery(this).scrollTop();
            if (y > 40) {
                jQuery('.footer-banner').slideDown();
            } else {
                jQuery('.footer-banner').slideUp();
            }
        });
        jQuery(document).on('click', '.scourse', function(){
            var name = jQuery(this).attr('c-name');
            jQuery('#crs').val(name);
            jQuery('#coursename').text(name);
            jQuery('#crs').trigger('change');
        });
        
        setTimeout(function() {
            $('#senfineApply').modal('show');
        }, 10000);
    },[url])
    
    
   return (
      <div className="app">
        <div className="footer-banner text-center">
            <div className="footer-container">
              <div className="button-section">
                 <a href="tel:+919845412266" className="btn btn-warning"><i className="fa fa-phone" aria-hidden="true"></i> Call us</a>
                 <a href="https://wa.me/919845412266?text=Senfine%20Academy%20Admission%20Enquiry%20" className="btn btn-success"><i className="fab fa-whatsapp" aria-hidden="true"></i> Chat</a>
                 <a href="#senfineQuery" data-toggle="modal" className="btn btn-danger"><i className="fa fa-paper-plane" aria-hidden="true"></i> Query?</a>
              </div>
            </div>
        </div>

<div id="senfineApply" className="modal senfine-modal fade" role="dialog">
  <div className="modal-dialog modal-md">

    <div className="modal-content reg-area">
      <div className="modal-header" style={{borderBottom:"0"}}>
        <button type="button" className="close" data-dismiss="modal">&times;</button>
        <h4 className="modal-title text-center text-uppercase">Admission Open</h4>
      </div>
      <div className="modal-body reg-form" style={{padding:"0"}}>
        <div className="modal-grid">
            <div className="left-section">
                <div className="modal-left-banner text-center">
                    <h5>Courses Offered</h5>
                    
                    <div className="modal-content-grid"> 
                        <div className="">
                            <ul>
                                <li>Basic Spoken English</li>
                                <li>Intermediate English Training</li>
                                <li>Corporate English Training</li>
                                <li>Soft Skills Training</li>
                                <li>Digital Marketing</li>
                                <li>Artificial Intelligence (AI)</li>
                                <li>Data Science</li>
                                <li>Cyber Security</li>
                                <li>Ethical Hacking</li>
                                <li>Cloud Computing</li>
                            </ul>
                        </div>
                        <div className="">
                            <img src="/assets/img/senfine-50off.webp" alt="50% Discount on Course Fee!" loading="lazy" draggable="false"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-section">
            <div className="modal-right-form">
        <h5 style={{color:"#0a9d7d", marginBottom:"15px", fontSize:"18px",fontWeight:"600",textAlign:"center"}}>Get Callback Now!</h5>
        <form onSubmit={handleSubmit} className="auto-popup">
            <div className="row">
                <div className="col-md-12 col-xs-12">
                    <div className="form-group">
                        <input className="form-control" name="student_name" placeholder="Name *" type="text" required/>
                    </div>
                </div>
               
                <div className="col-md-12 col-xs-12">
                    <div className="form-group">
                        <input className="form-control mobile" name="mobile" placeholder="Mobile Number *" type="text" required/>
                    </div>
                </div>
                <div className="col-md-12 col-xs-12">
                    <div className="form-group">
                        <input className="form-control" name="email" placeholder="Email *" type="email" required/>
                    </div>
                </div>
                <div className="col-md-12 col-xs-12">
                    <div className="form-group">
                    <Select options={courseOptions} required name="course" placeholder="Course Interested *" />
                    </div>
                </div>
                <div className="col-md-12 col-xs-12">
                    <div className="form-group">
                        <input className="form-control" name="city" placeholder="City *" type="text" required/>
                    </div>
                </div>
                <div className="col-md-offset-2 col-xs-offset-2 col-md-8 col-xs-8">
                    <button className="btn btn-primary effect" type="submit">
                       <i className="fa fa-check" aria-hidden="true"></i> Apply Now
                    </button>
                </div>
            </div>
        </form>
        </div>
      </div>
      </div>
      </div>
    </div>

  </div>
</div>

        <div id="senfineScholarships" className="modal senfine-modal fade" role="dialog">
            <div className="modal-dialog modal-sm">

                <div className="modal-content reg-area">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title">Get Scholarships</h4>
                </div>
                <div className="modal-body reg-form">
                    <h5 style={{color: '#2177bc', marginBottom: '10px'}}>Submit your details</h5>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="student_name" placeholder="Name *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control mobile" name="mobile" placeholder="Mobile Number *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="email" placeholder="Email *" type="email" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <Select options={courseOptions} required name="course" placeholder="Course Interested *" />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="city" placeholder="City *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group form-check" style={{marginTop: '10px'}}>
                                    <input type="checkbox" className="form-check-input" value="1" id="terms" name="terms" required />
                                    <label className="form-check-label" htmlFor="terms" style={{marginLeft: '4px'}}> I agree to allow Senfine Academy to contact me for providing the information.</label>
                                </div>
                            </div>
                            <div className="col-md-offset-3 col-xs-offset-3 col-md-6 col-xs-6">
                                <div className="form-group">
                                    <button className="btn btn-primary effect" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>

            </div>
        </div>

        <div id="senfineBrochure" className="modal senfine-modal fade" role="dialog">
            <div className="modal-dialog modal-sm">

                <div className="modal-content reg-area">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title">Get Brochure</h4>
                </div>
                <div className="modal-body reg-form">
                    <h5 style={{color: '#2177bc', marginBottom: '10px'}}>Submit your details</h5>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="student_name" placeholder="Name *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control mobile" name="mobile" placeholder="Mobile Number *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="email" placeholder="Email *" type="email" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <Select options={courseOptions} className="select2" required name="course" placeholder="Course Interested *" />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="city" placeholder="City *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-offset-3 col-xs-offset-3 col-md-6 col-xs-6">
                                <div className="form-group">
                                    <button className="btn btn-primary effect" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>

        <div id="senfineCourseEnquiry" className="modal senfine-modal fade" role="dialog">
            <div className="modal-dialog modal-sm">

                <div className="modal-content reg-area">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title">Apply for <span id="coursename"></span></h4>
                </div>
                <div className="modal-body reg-form">
                    <h5 style={{color: '#2177bc', marginBottom: '10px'}}>Submit your details</h5>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="student_name" placeholder="Name *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control mobile" name="mobile" placeholder="Mobile Number *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="email" placeholder="Email *" type="email" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <Select options={courseOptions} required name="course" placeholder="Course Interested *" id="crs" />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="city" placeholder="City *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-offset-3 col-xs-offset-3 col-md-6 col-xs-6">
                                <div className="form-group">
                                    <button className="btn btn-primary effect" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>

        <div id="senfineQuery" className="modal senfine-modal fade" role="dialog">
            <div className="modal-dialog modal-sm">

                <div className="modal-content reg-area">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title">Query?</h4>
                </div>
                <div className="modal-body reg-form">
                    <h5 style={{color: '#2177bc', marginBottom: '10px'}}>Submit your details</h5>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="student_name" placeholder="Name *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control mobile" name="mobile" placeholder="Mobile Number *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="email" placeholder="Email *" type="email" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <Select options={courseOptions} className="" required name="course" placeholder="Course Interested *" id="crs"/>
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <input className="form-control" name="city" placeholder="City *" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-offset-3 col-xs-offset-3 col-md-6 col-xs-6">
                                <div className="form-group">
                                    <button className="btn btn-primary effect" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>

            </div>
        </div>
        
        <div className="page-loader">
            <div className="page-loader-wrap">
                <RotatingLines
                   visible={true}
                   height="40"
                   width="40"
                   strokeColor="#0476bc"
                   strokeWidth="4"
                   animationDuration="0.75"
                   ariaLabel="Loading"
                />
                <p>Please wait...</p>
            </div>
        </div>

      </div>
   );
}

export default App;
