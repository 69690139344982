import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Helmet } from "react-helmet";
import "../pages/Home.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import jQuery from 'jquery';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import Select from 'react-select';

function Home() {

   AOS.init();  

   const [courses, setCourses] = useState([]);  
   const [allcourses, setAllCourses] = useState([]);  
   const [trendingcourses, setTrendingCourses] = useState([]); 
   const [popularcourses, setPopularCourses] = useState([]); 
   const [featuredcourses, setFeaturedCourses] = useState([]);  
   const [isOpen, setOpen] = useState(false);
   const [blogs, setBlogs] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const navigate = useNavigate();

   useEffect(()=>{
    const fetchCourses = async () => {
        setIsLoading(true)
        await axios.get(`/all-courses`).then(({data})=>{
            setCourses(data.courses)
            setAllCourses(data.allcourses)
            setTrendingCourses(data.trendingcourses)
            setPopularCourses(data.popularcourses)
            setFeaturedCourses(data.featuredcourses)
            setIsLoading(false)
        }).catch(({response:{data}})=>{
            setIsLoading(false)
            Swal.fire({
                text:'Error!.',
                icon:"error"
            })
        })
      }
      fetchCourses();
    },[]);

   useEffect(()=>{
     jQuery(".count").each(function () {
       jQuery(this)
         .prop("Counter", 0)
         .animate(
           {
             Counter: jQuery(this).text(),
           },
           {
             duration: 4000,
             easing: "swing",
             step: function (now) {
               now = Number(Math.ceil(now)).toLocaleString('en');
               jQuery(this).text(now);
             },
           }
         );
     });
     fetch('/home-page-data')
     .then((res) => {
         return res.json();
     })
     .then((data) => {
         //   console.log(data);
     setBlogs(data.blogs)
     });
   },[])
   
   function handleSubmit(event) {
         event.preventDefault();
         const formData = new FormData(event.target);
         jQuery(".page-loader").fadeIn(300);
         axios.post('/store-enquiry', formData)
         .then(({data})=>{
            jQuery(".page-loader").fadeOut(300);
            event.target.reset();
            if(data.status === 200){
              jQuery(window).scrollTop(0);
              navigate('/thanks');
            }
            else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields',
                    icon:"error"
                })
            }
            else{
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
            }
         })
       }
   
   function blogDate(par){
     const today = new Date(par);
     const date = today.getDate();
     return date;
   }
   function blogMonth(par){
     const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
     const today = new Date(par);
     const month = months[today.getMonth()];
     return month;
   }
   function blogYear(par){
     const today = new Date(par);
     const year = today.getFullYear();
     return year;
   }
   
   function courseInModal (val) {
        
   }

  return (
    <div className="home">
      {/* <Helmet>
        <title>Job Oriented Courses for Students & Professionals in MG Road, Bangalore | Senfine Academy</title>
        <meta name="description" content="Unlock success in the digital world with Senfine Academy's short-term job-oriented courses in MG Road, Bangalore. Master essential skills & be job-ready!!"/>
        <meta name="keywords" content="job oriented short term courses in bangalore, short term courses in bangalore, career advancement courses in bangalore, top certification courses in bangalore, top certification courses in bangalore, top trending courses in 2023, Online certification program, skill development certificate courses, career development certifications, Academic courses in mg road - bangalore, certification training course in mg road bangalore, skill development certificate course in mg road, short term certification courses in mg road"/>
        <link rel="canonical" href="https://www.senfineacademy.com/"/>
      </Helmet> */}

      <div className="banner-area content-top-heading text-normal">
        <Swiper 
          spaceBetween={0}
          navigation={{nextEl: ".swiper-next-slider", prevEl: ".swiper-prev-slider"}}
          breakpoints={{320: { slidesPerView: 1,}, 1024: { slidesPerView: 1,}}}
          loop={true}
          autoplay={{enable: "true", delay: "5000", pauseOnMouseEnter: "true",}}
        >
            <SwiperSlide>
                <div className="item">
                    <div className="box-table bg-cover dark" style={{backgroundImage: 'linear-gradient(5deg, rgb(20 0 32 / 0%), rgb(0 22 32 / 0%)), url(assets/img/senfine_banner_new.webp)'}}>
                    <div className="box-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-xs-9">
                                    <div className="content">
                                        <h3 data-aos="zoom-out-right" data-aos-offset="10" data-aos-duration="1000" data-aos-delay="200">Admission Open 2025-26</h3>
                                        <ul data-aos="zoom-out-right" data-aos-offset="10" data-aos-duration="1000" data-aos-delay="400">
                                            <Link to="/courses/spoken-english-classes-in-bangalore"><li>Basic Spoken English</li></Link>
                                            <Link to="/courses/spoken-english-classes-in-bangalore"><li>Intermediate English Training</li></Link>
                                            <Link to="/courses/spoken-english-classes-in-bangalore"><li>Corporate English Training</li></Link>
                                            <Link to="/courses/best-soft-skills-classes-in-bangalore"><li>Soft Skills Training</li></Link>
                                            <Link to="/courses/best-digital-marketing-courses-in-bangalore"><li>Digital Marketing</li></Link>
                                            <Link to="/courses/artificial-intelligence-machine-learning"><li>Artificial Intelligence (AI)</li></Link>
                                            <Link to="/courses/data-science"><li>Data Science</li></Link>
                                            <Link to="/courses/cyber-security-course-in-bangalore"><li>Cyber Security</li></Link>
                                            <Link to="/courses/ethical-hacking"><li>Ethical Hacking</li></Link>
                                            <Link to="/courses/cloud-computing-certification-course"><li>Cloud Computing</li></Link>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xs-12 hidden-xs">
                                    <div className="content text-right">
                                        <h4 data-aos="zoom-out-left" data-aos-offset="10" data-aos-duration="1000" data-aos-delay="500">Securing Your<br />Future!</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="item">
                    <div className="box-table bg-cover dark" style={{backgroundImage: 'linear-gradient(5deg, rgb(20 0 32 / 40%), rgb(0 22 32 / 40%)), url(assets/img/senfine_banner_new-2.webp)'}}>
                    <div className="box-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-xs-12">
                                    <div className="text-center slide-second">
                                        <h3 className="text-second" data-aos="zoom-out-down" data-aos-offset="10" data-aos-duration="1000" data-aos-delay="200">Upgrade and Master your Skills</h3>
                                        <Link to="/courses" className="btn btn-primary btn-sm" data-aos="zoom-out-up" data-aos-offset="10" data-aos-duration="1000" data-aos-delay="400">View Courses</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </SwiperSlide>
                    
        </Swiper>
                
        <div className="swiper-nav hidden-xs">
            <button type="button" role="presentation" className="swiper-prev swiper-prev-slider"><i className="fa fa-angle-left"></i></button>
            <button type="button" role="presentation" className="swiper-next swiper-next-slider"><i className="fa fa-angle-right"></i></button>
        </div>
        
      </div>
      <div className="about-area senfine-features-area">
        <div className="container">
            <div className="row">
                <div className="our-features">
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-education"></i>
                            </div>
                            <div className="info">
                                <h4><span className="count">30</span>+</h4>
                                <p>Top Recruiting Companies</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-teacher-lecture-in-front-an-auditory"></i>
                            </div>
                            <div className="info">
                                <h4><span className="count">40</span>+</h4>
                                <p>Expert Faculty Members</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-reading"></i>
                            </div>
                            <div className="info">
                                <h4><span className="count">48</span>+</h4>
                                <p>Courses</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-learning"></i>
                            </div>
                            <div className="info">
                                <h4><span className="count">1000</span>+</h4>
                                <p>Students</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="about-info">
                    <div className="col-md-6 info" data-aos="fade-up" data-aos-offset="0" data-aos-duration="800" data-aos-delay="0">
                        <h1 style={{textTransform: "initial"}}>Welcome to Senfine Academy</h1>
                        <p>At Senfine Academy, we believe in transforming careers with our high-class professional courses. All our <b>Best Academic certification courses</b> are specifically designed by industry experts to provide knowledge of a functional nature that matters and works in the workplace. Be it as a working professional or an ambitious student, our courses would help bridge that gap and move effortlessly into new domains, setting one on the path to a fulfilling career and offering solid job placement assistance so that its students realize their dream jobs after completing the courses.</p>
                        <p>Learn and Grow with us. The focus at Senfine on practical knowledge will ensure you gain the required experience and industry-relevant skills in your respective field of interest, making you earn a valuable position in any professional setting. Invest in your future with Senfine’s Job-oriented programs. Your success means everything to us here at Senfine Academy. </p>
                        <Link to="/about-us" className="btn btn-dark btn-xsm border">Read More</Link>
                    </div>
                    <div className="col-md-6 video">
                        <ModalVideo
                          channel="youtube"
                          youtube={{ mute: 0, autoplay: 1 }}
                          isOpen={isOpen}
                          videoId="D9h9G1nnv5k"
                          onClose={() => setOpen(false)}
                        />
                        <img src="/assets/img/video-thumb-1.webp" loading="lazy" draggable="false" alt="Senfine Academy Video" />
                        <div className="overlay-video">
                            <button className="popup-youtube video-play-button" onClick={() => setOpen(true)}><i className="fa fa-play"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="search-area default-padding bg-gray">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h3>Find the right course for you...</h3>
                    <div className="search-box">
                        <div className="row">
                        <div className="col-md-12 info">
                            <form onSubmit={handleSubmit} className="">
                            <div className="search-form-grid">
                                <div className="grid-item">
                                    <div className="form-group">
                                        <label>I would like to Join:</label>
                                        <Select options={[
                                            { value: 'Spoken English Training', label: 'Spoken English Training' },
                                            { value: 'Soft Skills Training', label: 'Soft Skills Training' },
                                            { value: 'Digital Marketing Certification', label: 'Digital Marketing Certification' }
                                        ]} required name="course" placeholder="Course Interested *" />
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="form-group">
                                        <label>My Name:</label>
                                        <input className="form-control" name="student_name" placeholder="Name *" type="text" required />
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="form-group">
                                        <label>My Email:</label>
                                        <input className="form-control" name="email" placeholder="Email *" type="email" required />
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="form-group">
                                        <label>My Mobile number:</label>
                                        <input className="form-control mobile" name="mobile" placeholder="Mobile *" type="text" required />
                                    </div>
                                </div>
                                <div className="grid-item">
                                <button type="submit" className="btn">Submit</button>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="reviews-section text-center">
                        <p>98% of our Students rate their Experience and Teaching as 'Great' or 'Excellent'.</p>
                        <img src="/assets/img/google_logo.svg" loading="lazy" draggable="false" alt="Senfine Academy Google Ratings" title="Senfine Academy Google Ratings" />
                        <div className="rating">
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star-half-alt"></i>
                            <span> 4.8</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div className="top-courses default-padding carousel-shadow text-center bg-fixed dark-hard" style={{backgroundImage: 'url(assets/img/top-courses-bg.webp)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="site-heading text-left">
                        <h2>Top 10 Trending Courses for 2025</h2>
                    </div>
                </div>
                <div className="top-courses-items">
                <div className="row">
                <div className="col-md-12">
                <Swiper 
                  spaceBetween={20}
                  navigation={{nextEl: ".swiper-next-trending", prevEl: ".swiper-prev-trending"}}
                  breakpoints={{320: { slidesPerView: 2,}, 1024: { slidesPerView: 4,}}}
                  loop={true}
                  autoplay={{enable: "true", delay: "2000", pauseOnMouseEnter: "true",}}
                >
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/data-science" title="Data Science Certification Course">
                        <div className="fun-fact c1">
                            <div className="icon">
                                <i className="flaticon-code"></i>
                            </div>
                            <div className="info">
                                <h4>Data Science Certification Course</h4>
                            </div>
                        </div>
                        </a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/artificial-intelligence-machine-learning" title="AI & ML Certification Course">
                        <div className="fun-fact c2">
                            <div className="icon">
                                <i className="flaticon-artificial-intelligence"></i>
                            </div>
                            <div className="info">
                                <h4>AI & ML Certification Course</h4>
                            </div>
                        </div>
                        </a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/cybersecurity-internet-of-things-iot" title="Certification in Cyber Security">
                        <div className="fun-fact c3">
                            <div className="icon">
                                <i className="flaticon-artificial-intelligence"></i>
                            </div>
                            <div className="info">
                                <h4>Certification in Cyber Security</h4>
                            </div>
                        </div>
                        </a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/cloud-computing-certification-course" title="Certification in Cloud Computing">
                        <div className="fun-fact c4">
                            <div className="icon">
                                <i className="flaticon-interaction"></i>
                            </div>
                            <div className="info">
                                <h4>Certification in Cloud Computing</h4>
                            </div>
                        </div>
                        </a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/digital-marketing-certification-training-course-in-bangalore" title="Digital Marketing Certification Course">
                        <div className="fun-fact c1">
                            <div className="icon">
                                <i className="flaticon-learning"></i>
                            </div>
                            <div className="info">
                                <h4>Digital Marketing Certification Course</h4>
                            </div>
                        </div>
                        </a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/web-design-and-development" title="Web Design & Development Course">
                        <div className="fun-fact c2">
                            <div className="icon">
                                <i className="flaticon-web-design"></i>
                            </div>
                            <div className="info">
                                <h4>Web Design & Development Course</h4>
                            </div>
                        </div>
                        </a>
                    </div> 
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/big-data" title="Certification Course in Big Data">
                        <div className="fun-fact c3">
                            <div className="icon">
                                <i className="flaticon-reading"></i>
                            </div>
                            <div className="info">
                                <h4>Certification Course in Big Data</h4>
                            </div>
                        </div>
                        </a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/business-analytics" title="Business Analytics Certification Course">
                        <div className="fun-fact c4">
                            <div className="icon">
                                <i className="flaticon-university-campus"></i>
                            </div>
                            <div className="info">
                                <h4>Business Analytics Certification Course</h4>
                            </div>
                        </div>
                        </a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/international-business-management" title="International Business Management">
                        <div className="fun-fact c1">
                            <div className="icon">
                                <i className="flaticon-book-1"></i>
                            </div>
                            <div className="info">
                                <h4>International Business Management</h4>
                            </div>
                        </div>
                        </a>
                    </div> 
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        <a href="/courses/professional-certification-in-graphic-design" title="Certification in Graphic Designing">
                        <div className="fun-fact c2">
                            <div className="icon">
                                <i className="flaticon-faculty-shield"></i>
                            </div>
                            <div className="info">
                                <h4>Certification in Graphic Designing</h4>
                            </div>
                        </div>
                        </a>
                    </div>
                    </SwiperSlide>
                </Swiper>
                
                <div className="swiper-nav hidden-xs">
                    <button type="button" role="presentation" className="swiper-prev swiper-prev-trending"><i className="fa fa-angle-left"></i></button>
                    <button type="button" role="presentation" className="swiper-next swiper-next-trending"><i className="fa fa-angle-right"></i></button>
                </div>
                
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div className="about-area default-padding bg-gray hidden-xs">
        <div className="container">
            <div className="row">
                <div className="about-info">
                    <div className="col-md-12 info">
                        <h2>Step Up with Top Training and Placement Institutes in Bangalore </h2>
                        <p>Senfine Academy undoubtedly stands out as one of the <b>Top Training and Placement Institutes in Bangalore</b> that emphasizes real-world skills and career-oriented training rather than simple classroom teaching. For a change, Senfine Academy lays emphasis on personalized learning experiences, active training, and practical exposure so that the students learn not only the theories but also the skills relevant for the industry. With a keen commitment to placement, Senfine Academy works hand in hand with top firms. Such a unique blend of expert guidance, industry-relevant courses, and dedicated placement assistance from the academy cements its leading position in the transformation of careers.</p>
                        <p>Ready to boost your skills and fast-track your career? Discover the <b>Best Short-Term Courses in Bangalore</b> at <b>Senfine Academy</b>. Our Top certification courses are designed to give you the exact skills you need in today’s competitive job market. With expert training, active practice, and real-world insights, you’ll gain the confidence to step into new opportunities quickly. Don’t just learn but experience the difference with Senfine Academy’s focused and career-oriented programs that set you apart from the rest.</p>
                        <p>Why wait to upgrade your career? Enroll in our <b>Top certification courses in Bangalore</b> and unlock a world of opportunities. Conveniently located at the Heart of Bangalore city, MG Road, <b>Senfine Academy offers the best of training and placement</b> assistance, ensuring you’re not just learning but also connecting with the right employers. Possibly, investing in personal and professional growth is one of the wisest decisions you will ever make, and with Senfine Academy, this gets even easier. Start your journey to success with Senfine Academy. Your next big step is just an enrollment away!</p>
                        <Link to="/application-form" className="btn btn-theme b1 btn-xsm border">Enroll Now <i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                    </div>
                   
                </div>
                
            </div>
        </div>
    </div>

    <div className="popular-courses circle default-padding without-carousel">
        <div className="container">
            <div className="row">
                <div className="site-heading text-left">
                    <div className="col-md-6">
                        <h2>Certification Programs</h2>
                    </div>
                    <div className="col-md-6">
                        <ul className="filter-btns clearfix" id="filters">
                            <li><a href="#catAll" data-toggle="tab">All</a></li>
                            <li className="active"><a href="#catTrending" data-toggle="tab">Trending</a></li>
                            <li><a href="#catPopular" data-toggle="tab">Popular</a></li>
                            <li><a href="#catFeatured" data-toggle="tab">Featured</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {isLoading ? (
            <div className="row">
            <div className="col-md-12 text-center">
                <RotatingLines
                   visible={true}
                   height="40"
                   width="40"
                   strokeColor="#0476bc"
                   strokeWidth="4"
                   animationDuration="0.75"
                   ariaLabel="Loading"
                />
                <p>Please wait...</p>
            </div>
            </div>
            ) : (
            <div className="row">
                <div className="col-md-12">
                  <div className="popular-courses-items tab-content">
                    <div className="row flex tab-pane fade" id="catAll">
                    {allcourses.map((course, index) => (
                        <div className="col-sm-12 col-md-3" key={index}>
                        <div className="item">
                            <div className="thumb">
                                <Link to={`/courses/${course.slug}`}>
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                </Link>
                            </div>
                            <div className="info">
                                <Link to="#senfineCourseEnquiry" data-toggle="modal" data-target="#senfineCourseEnquiry" c-name={course.course} className="btn btn-theme btn-xsm scourse b2">Apply Now</Link>
                                <h4><Link to={`/courses/${course.slug}`}>{course.course}</Link></h4>
                                <p>
                                {course.institution}
                                </p>
                                <div className="author-info">
                                    <div className="others">
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                            <span> {course.rating} ({course.rating_count})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                    <div className="row flex tab-pane fade in active" id="catTrending">
                    {trendingcourses.map((course, index) => (
                        <div className="col-sm-12 col-md-3" key={index}>	
                        <div className="item">
                            <div className="thumb">
                                <Link to={`/courses/${course.slug}`}>
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                </Link>
                            </div>
                            <div className="info">
                                <Link onClick={courseInModal} to="#senfineCourseEnquiry" data-toggle="modal" data-target="#senfineCourseEnquiry" c-name={course.course} className="btn btn-theme btn-xsm scourse b2">Apply Now</Link>
                                <h4><Link to={`/courses/${course.slug}`}>{course.course}</Link></h4>
                                <p>
                                {course.institution}
                                </p>
                                <div className="author-info">
                                    <div className="others">
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                            <span> {course.rating} ({course.rating_count})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                    <div className="row flex tab-pane fade" id="catPopular">
                    {popularcourses.map((course, index) => (	
                        <div className="col-sm-12 col-md-3" key={index}>
                        <div className="item">
                            <div className="thumb">
                                <Link to={`/courses/${course.slug}`}>
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                </Link>
                            </div>
                            <div className="info">
                                <Link to="#senfineCourseEnquiry" data-toggle="modal" data-target="#senfineCourseEnquiry" c-name={course.course} className="btn btn-theme btn-xsm scourse b2">Apply Now</Link>
                                <h4><Link to={`/courses/${course.slug}`}>{course.course}</Link></h4>
                                <p>
                                {course.institution}
                                </p>
                                <div className="author-info">
                                    <div className="others">
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                            <span> {course.rating} ({course.rating_count})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                    <div className="row flex tab-pane fade" id="catFeatured">
                    {featuredcourses.map((course, index) => (
                        <div className="col-sm-12 col-md-3" key={index}>
                        <div className="item">
                            <div className="thumb">
                                <Link to={`/courses/${course.slug}`}>
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                </Link>
                            </div>
                            <div className="info">
                                <Link to="#senfineCourseEnquiry" data-toggle="modal" data-target="#senfineCourseEnquiry" c-name={course.course} className="btn btn-theme btn-xsm scourse b2">Apply Now</Link>
                                <h4><Link to={`/courses/${course.slug}`}>{course.course}</Link></h4>
                                <p>
                                {course.institution}
                                </p>
                                <div className="author-info">
                                    <div className="others">
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                            <span> {course.rating} ({course.rating_count})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                  </div>
                </div>
            </div>
            )}
            <div className="row">
                <div className="col-md-6 col-md-offset-3">
                    <div className="find-box">
                        <div className="row">
                        <div className="col-md-12 info">
                            <i className="fas fa-bolt fa-fw"></i>
                            <div className="row">
                                <div className="col-md-8">
                                <p>
                                    Let Us Help
                                </p>
                                <h4>
                                    Find Your Right Courses
                                </h4>
                                </div>
                                <div className="col-md-4">
                                <a href="/courses" className="btn btn-xsm">Get Started</a>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="why-choose default-padding">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="site-heading text-left">
                        <h3>Why Senfine?</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="why-choose-boxes why-choose-carousel text-center">
                    <Swiper id="why-choose-carousel" 
                        spaceBetween={20}
                        navigation={{nextEl: ".swiper-next-whys", prevEl: ".swiper-prev-whys"}}
                        breakpoints={{320: { slidesPerView: 1,}, 1024: { slidesPerView: 5,}}}
                        loop={true}
                        autoplay={{enable: "true", delay: "5000", pauseOnMouseEnter: "false",}}
                        > 
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="50">
                            <div className="circle"><i className="flaticon-teacher-lecture-in-front-an-auditory"></i></div>
                            <div className="info">
                                <h4>Live Interaction Classes</h4>
                                <p>Senfine offers live classes where students can interact with instructors in real time, ensuring active engagement and immediate clarification of doubts.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-online"></i></div>
                            <div className="info">
                                <h4>Recorded Classes in Portal</h4>
                                <p>Each className is recorded and accessible through the Senfine portal, allowing students to review the material at their convenience and reinforce their learning.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-online-course"></i></div>
                            <div className="info">
                                <h4>Customised One-to-one Classes</h4>
                                <p>Senfine offers personalised one-on-one classes upon request, catering to the specific learning pace and requirements of individual students.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-learning"></i></div>
                            <div className="info">
                                <h4>Assignments Management</h4>
                                <p>Assignments are distributed through the portal, streamlining the submission process and enabling efficient tracking of student progress.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-presentation"></i></div>
                            <div className="info">
                                <h4>Faculty Feedback</h4>
                                <p>Students receive feedback from faculty members directly through the portal, facilitating communication and fostering continuous improvement.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-online-course"></i></div>
                            <div className="info">
                                <h4>Faculty Replacement Option</h4>
                                <p>Senfine offers the flexibility for students to request a faculty replacement if they feel uncomfortable with the current instructor, ensuring a conducive learning environment.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-online"></i></div>
                            <div className="info">
                                <h4>Enrichment Activities</h4>
                                <p>Senfine organises workshops, presentations, group discussions, and seminars to supplement classroom learning and enhance students' overall educational experience.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-teacher-lecture-in-front-an-auditory"></i></div>
                            <div className="info">
                                <h4>Real-time Project Training</h4>
                                <p>Students receive hands-on training in real-time projects relevant to their technical courses, preparing them for practical challenges in their future careers.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-presentation"></i></div>
                            <div className="info">
                                <h4>⁠Internship Opportunities</h4>
                                <p>Senfine facilitates internships, providing students with valuable industry experience and practical exposure to complement their academic learning.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-online-course"></i></div>
                            <div className="info">
                                <h4>⁠Convenient Location</h4>
                                <p>Located in the center of the city, Senfine's center offers easy accessibility for students, ensuring convenient transportation options.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-teacher-lecture-in-front-an-auditory"></i></div>
                            <div className="info">
                                <h4>Online and Offline Classes</h4>
                                <p>Senfine offers both online and offline classes, providing flexibility for students to choose their preferred mode of learning based on their convenience and accessibility.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-presentation"></i></div>
                            <div className="info">
                                <h4>Modern Infrastructure</h4>
                                <p>Senfine boasts modern infrastructure with digital classroom facilities, creating an immersive learning environment conducive to effective teaching and learning.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-online"></i></div>
                            <div className="info">
                                <h4>⁠Digital Library Access</h4>
                                <p>Students have access to a digital library, providing a wealth of resources and materials to supplement their studies and research.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-teacher-lecture-in-front-an-auditory"></i></div>
                            <div className="info">
                                <h4>⁠Experienced Faculties</h4>
                                <p>Senfine boasts a team of experienced faculty members who bring industry knowledge and expertise to the classroom, enriching the learning experience for students.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-online-course"></i></div>
                            <div className="info">
                                <h4>⁠Job Guarantee</h4>
                                <p>Senfine offers a hundred percent job guarantee for technical courses, instilling confidence in students about their career prospects upon completion of their studies.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-teacher-lecture-in-front-an-auditory"></i></div>
                            <div className="info">
                                <h4>⁠Industry Expert's Session</h4>
                                <p>Students benefit from classes conducted by industry experts, gaining insights and perspectives directly from professionals working in the field.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-presentation"></i></div>
                            <div className="info">
                                <h4>⁠International Collaborations</h4>
                                <p>Senfine Academy has international collaborations, providing students with exposure to global perspectives and opportunities for cross-cultural learning.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-online"></i></div>
                            <div className="info">
                                <h4>Career Planning and Guidance</h4>
                                <p>Senfine offers career planning and guidance services, assisting students in charting their career paths and making informed decisions about their future.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="why-choose-single" data-aos="zoom-in-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="circle"><i className="flaticon-learning"></i></div>
                            <div className="info">
                                <h4>Scholarships</h4>
                                <p>Senfine provides scholarships of up to 50%, making quality education more accessible to deserving students and alleviating financial burdens.</p>
                            </div>
                        </div>
                        </SwiperSlide>
                    </Swiper>
                        <div className="swiper-nav hidden-xs">
                            <button type="button" role="presentation" className="swiper-prev swiper-prev-whys"><i className="fa fa-angle-left"></i></button>
                            <button type="button" role="presentation" className="swiper-next swiper-next-whys"><i className="fa fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="alumni-logos default-padding bg-gray">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="site-heading text-left">
                        <h3>Senfine Alumni Work At</h3>
                    </div>
                </div>
            </div>
            <div className="alumni-logos-carousel">
            <Swiper id="alumni-logos-carousel" 
                spaceBetween={20}
                breakpoints={{320: { slidesPerView: 3,}, 1024: { slidesPerView: 8,}}}
                loop={true}
                autoplay={{enable: true, delay: 500, pauseOnMouseEnter: false,}}
                speed={2000}
            >
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/tcs.png" loading="lazy" draggable="false" alt="tcs" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/infosys.png" loading="lazy" draggable="false" alt="infosys" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/wipro.png" loading="lazy" draggable="false" alt="wipro" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/deloitte.png" loading="lazy" draggable="false" alt="deloitte" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/microsoft.png" loading="lazy" draggable="false" alt="microsoft" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/google.png" loading="lazy" draggable="false" alt="google" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/amazon.png" loading="lazy" draggable="false" alt="amazon" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/mphasis.png" loading="lazy" draggable="false" alt="mphasis" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/meta.png" loading="lazy" draggable="false" alt="meta" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/apple.png" loading="lazy" draggable="false" alt="apple" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/samsung.png" loading="lazy" draggable="false" alt="samsung" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/unilever.png" loading="lazy" draggable="false" alt="unilever" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/hdfc.png" loading="lazy" draggable="false" alt="hdfc" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/icici.png" loading="lazy" draggable="false" alt="icici" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/airtel.png" loading="lazy" draggable="false" alt="airtel" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <img src="assets/img/logos/jio.png" loading="lazy" draggable="false" alt="jio" />
                    </div>
                </SwiperSlide>
            </Swiper>
            </div>
        </div>
    </div>
    <div className="subscribe-area text-center text-light default-padding bg-fixed shadow dark-hard" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="content">
                        <h3>Boost Your Career with Senfine Skill Development Courses!</h3>
                        <Link to="/courses" className="btn btn-theme b1 btn-xsm">View All Courses <i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
      
    <div className="testimonials-area default-padding bg-gray">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="site-heading text-left">
                            <h4>Inspired Achievements</h4>
                            <p className="mb-0">
                                Read reviews from the Community of Senfine Academy.
                            </p>
 
                    </div>
                </div>
            </div>
            <div className="row">
                    <div className="clients-review-carousel">
                    <Swiper id="clients-review-carousel"
                        spaceBetween={10}
                        pagination={{clickable: "true"}}
                        breakpoints={{ 320: { slidesPerView: 1,}, 1024: { slidesPerView: 3,}}}
                        loop={true}
                        autoplay={{enable: "true", delay: "2000", pauseOnMouseEnter: "true",}}
                    >
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/avatar0.jpg" loading="lazy" draggable="false" alt="Nikhil" />
                                    </div>
                                    <div className="others">
                                        <h4>Nikhil</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    My immense experience started on the first day, when I realised I was not just in a academy but in the corporate world. In addition to the wonderful teaching, a variety of activities were conducted to help us discover our hidden talents, build our strengths, and learn how to work as a team to overcome our weaknesses. There is a good learning environment that is enjoyable and friendly, with knowledgeable teaching staff.
                                </p>
                                </div>
                                
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/senfine-testimonial-chandanakoti.jpg" loading="lazy" draggable="false" alt="Chandana Koti" />
                                    </div>
                                    <div className="others">
                                        <h4>Chandana</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    I am happy that I chose Senfine Academy to complete my AI & MI coursework because all of the faculty members provide excellent care for their students both academically and extra curricularly. The college's placement assistants are to be commended for advancing my careers because they give all students effective pre-placement training that prepares us for the workplace and gives us all the chance to realise our dreams.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/y.png" loading="lazy" draggable="false" alt="" />
                                    </div>
                                    <div className="others">
                                        <h4>Yashuraj</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    As far as the Academic is concerned, the Senfine syllabus provides an excellent foundation for a smooth take off in my careers. After I joined Senfine, I gained extensive knowledge about management and business, as well as a path to follow as I built my career and became an authority in finance and human resources. The distinctive characteristics of Senfine Academy set it apart from other institutes.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/k.png" loading="lazy" draggable="false" alt="" />
                                    </div>
                                    <div className="others">
                                        <h4>Komal</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    Senfine Academy has been a great contributor to the development of my personality. I have established my leadership, time management and team skills and have also been able to advance these skills to the whole new augment level. The infrastructure of Senfine is one of the finest in the Bangalore is what stands out the most. The best thing about this academy is the cross-culture interaction between students from various parts of the country.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        </Swiper>
                    </div>
                
                <div className="mt-1 text-center">
                    <Link to="/testimonials" className="btn btn-theme b1 btn-xsm border">View all Reviews</Link>
                </div>
            </div>
        </div>
    </div>
    <div id="blogs" className="blog-area blog-area-home default-padding">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="site-heading text-left">
                        <h4>Latest Blog Posts</h4>
                        <p className="mb-0">
                            Check out our Blogs for free insights and updates on your field.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="blog-items">
                    <div className="blog-content blogs-carousel" id="blogs-home">
                    <Swiper id="blogs-carousel"
                        spaceBetween={10}
                        pagination={{clickable: "true"}}
                        breakpoints={{ 320: {slidesPerView: 1,}, 1024: { slidesPerView: 3,}}}
                        loop={true}
                        autoplay={{enable: "true", delay: "2000", pauseOnMouseEnter: "true"}}
                    >
                        {blogs && blogs.length > 0 && blogs.map((blog, index) => {
						return (
                        <SwiperSlide key={`b${blog.id}`}>
                        <div className="single-item">
                            <div className="item"  >
                                <div className="thumb">
                                    <Link to={`/blogs/${blog.slug}`}><img src={`/storage/blogs/${blog.image}`} loading="lazy" draggable="false" alt={blog.title} /></Link>
                                    <div className="date">
                                        <h4><span>{blogDate(new Date(blog.updated_at))}</span> {blogMonth(new Date(blog.updated_at))}, {blogYear(new Date(blog.updated_at))}</h4>
                                    </div>
                                </div>
                                <div className="info">
                                    <p>
                                        {blog.title}
                                    </p>
                                    <Link to={`/blogs/${blog.slug}`}>Read More <i className="fas fa-angle-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        );
                        })}
                    </Swiper>
                    </div>
                </div>
                <div className="mt-1 text-center">
                    <Link to="/blogs" className="btn btn-theme b1 btn-xsm border">View all Blogs</Link>
                </div>
            </div>
        </div>
    </div>
    <div className="about-area demo-cert-area">
        <div className="container">
            <div className="demo-cert">
                <div className="left-side">
                    <div className="site-heading text-left">
                        <h2>Why Senfine Academy is the Best Training Institute in Bangalore?</h2>
                    </div>
                    <ul>
                        <li><i className="fas fa-desktop"></i> Live Capstone Projects</li>
                        <li><i className="fas fa-graduation-cap"></i> Real time Industry Experts as Trainers</li>
                        <li><i className="fas fa-trophy"></i> Placement Support till you get your Dream Job offer!</li>
                        <li><i className="fas fa-chalkboard-teacher"></i> Free Interview Clearing Workshops</li>
                        <li><i className="far fa-file-alt"></i> Free Resume Preparation & Aptitude Workshops</li>
                    </ul>
                    <div className="col-md-offset-4 col-xs-offset-3 col-md-4 col-xs-6 text-center">
                        <Link className="btn btn-primary" style={{marginBottom:"10px", width:"100%"}} to="#senfineApply" data-toggle="modal" data-target="#senfineApply"><i className="fa fa-check" aria-hidden="true"></i> Apply Now</Link>
                    </div>
                </div>
                <div className="right-side">
                    <div className="cert-wrap" data-aos="flip-up" data-aos-offset="0" data-aos-duration="1000" data-aos-delay="50">
                        <span className="top-view"></span>
                        <img src="assets/img/demo_cert1.webp" alt="Senfine Demo Certificate" loading="lazy" draggable="false"/>
                        <span className="bottom-view"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}

export default Home;
